import React from "react";

const ResponsiveImage = ({ mobil, tablet, pc, large }) => {
  return (
    <picture>
      <source media="(min-width: 1440px)" srcSet={large} />
      <source media="(min-width: 1024px) and (max-width: 1440px)" srcSet={pc} />
      <source
        media="(min-width: 768px) and (max-width: 1024px)"
        srcSet={tablet}
      />
      <source media="(max-width: 768px)" srcSet={mobil} />
      <img src={large} alt="Responsive example" />
    </picture>
  );
};

export default ResponsiveImage;
