import Slider from "components/video-slider/Slider";
import React from "react";
import "styles/membershipCard.css";
import infoMembership from "assets/membership.json";
import OutlinedText from "common/OutlinedText";

const MembershipCard = () => {
  return (
    <section className="container-membership">
      <OutlinedText
        position={true}
        positionTop1="10%"
        left_text1="15%"
        text1="Membresias"
        text2="Membresias"
        color="var(--azul-gac)"
        positionTop2="0"
        left_text2="5%"
      />
      <Slider
        showAlternButtons={true}
        showIndicators={false}
        showButtons={false}
      >
        {/* Pairing the cards in the Slider */}
        {infoMembership.map((_, index) => {
          const prevIndex = index === 0 ? infoMembership.length - 1 : index - 1;
          return (
            <div key={index} className="slider-pair">
              {/* Left card (Active) */}
              <div
                className="card-membership"
                style={{
                  "--imageMembership": `url(${infoMembership[index].image})`,
                }}
              >
                <h4 className="name-membership color-title">
                  {infoMembership[index].membership}
                </h4>
                <div className="active-card-membership">
                  <h4 className="color-title">Membresía:</h4>
                  <h4>{infoMembership[index].membership}</h4>
                  <h4 className="color-title">Vigencia:</h4>
                  <p>{infoMembership[index].validity}</p>

                  <h4 className="color-title mt-5">Beneficios:</h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: infoMembership[index].benefits,
                    }}
                  ></p>
                </div>
              </div>

              {/* Right card (Inactive) */}
              <div
                className="card-membership "
                style={{
                  "--imageMembership": `url(${infoMembership[prevIndex].image})`,
                }}
              >
                <div className="name-membership color-title">
                  <h4>{infoMembership[prevIndex].membership}</h4>
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default MembershipCard;
