import React, { useEffect } from "react";
import { embedCodes } from "assets/InstagramCode";
import "styles/InstagramVideos.css";

const InstagramVideo = ({ embedCode }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//www.instagram.com/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="videos-container">
      {embedCodes.map((item) => (
        <div
          key={item.id}
          className="video-wrapper"
          dangerouslySetInnerHTML={{ __html: item.embedCode }}
        />
      ))}
    </div>
  );
};

export default InstagramVideo;
