import React from "react";
import "styles/banner-text.css";

const BannerText = ({ children, Image }) => {
  return (
    <div className="container-text">
      {children}
      <img src={Image} alt="Example" className="image" />
    </div>
  );
};

export default BannerText;
