import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import blogs from "assets/Blogs.json";
import AsideBlogs from "components/AsideBlogs";
import SlideImages from "components/SlideImages";

const Blog = () => {
  const [Info, setInfo] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const selected = blogs.filter((b) => b.id === id);
    setInfo(selected[0]);
  }, [id]);
  return (
    <>
      <div className="contenedor-relative">
        <img loading="lazy" src="/images/Blog.jpg" alt="" width={"100%"} />
        <h1 className="title-section">BLOG</h1>
      </div>
      <div className="container p-2 mt-1 text-center">
        <NavLink className="text-back" onClick={() => navigate(-1)}>
          <i className="fa-solid fa-arrow-left"></i>
          Volver
        </NavLink>
        <div className="grid-row-blog">
          <div className="column">
            <h3 className="title-about mb-3 text-center">{Info?.title}</h3>
            <SlideImages images={Info?.img} />{" "}
            {/* Componente de slide de imagenes */}
            <figcaption className="mt-2">
              Tomada:{" "}
              <cite>{Info?.imageAuthor ? Info?.imageAuthor : "Pixabay"}</cite>
            </figcaption>
            <p
              className="justify mt-3"
              dangerouslySetInnerHTML={{ __html: Info?.text }}
            ></p>
          </div>
          <div className="column">
            <AsideBlogs blogs={blogs} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;
