import React from "react";
import OutlinedText from "./OutlinedText";
import Slider from "components/video-slider/Slider";
import ImageTestimonials from "assets/images/questions/testimonios.webp";
import textTestimonials from "assets/testimonials.json";
import "styles/testimonials.css";

const Testimonials = () => {
  console.log(typeof textTestimonials);

  return (
    <section className="section-testimonials">
      <OutlinedText
        position={true}
        positionTop1="30px"
        left_text1="1%"
        text1="Testimonios"
        text2="Testimonios"
        color="var(--azul-gac)"
        positionTop2="0px"
        left_text2="0%"
      />

      <article>
        <Slider
          showIndicators={true}
          showButtons={true}
          arrowColor="var(--azul-gac)"
          colorIndicator="var(--azul-gac)"
          position="relative"
        >
          {textTestimonials.map(({ username, opinion }) => (
            <article key={username} className="container-opinion">
              <div className="hexagon-container">
                <img
                  src={ImageTestimonials}
                  alt="Hexagon"
                  className="hexagon-image"
                />
              </div>

              <div className="opinion">
                <h6 className="fw-700">{username}</h6>
                <p className="color-p">{opinion}</p>
              </div>
            </article>
          ))}
        </Slider>
      </article>
    </section>
  );
};

export default Testimonials;
