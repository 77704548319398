import React from "react";
import { NavLink } from "react-router-dom";
import "styles/navbar.css";

const Navbar = ({ isScrolled }) => {
  return (
    <nav className={`header ${isScrolled && "sticky1 scrolled"}`}>
      <NavLink to="/">
        <img
          loading="lazy"
          src="/images/logo/logo.webp"
          alt="Grupo Alianza Colombia Viajar"
          width={200}
          className={`${isScrolled ? "logo1" : "not-show"}`}
        />
      </NavLink>
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Inicio
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Nosotros
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/blog"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Blog
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contacto"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Contáctanos
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/questions"
            className={({ isActive }) =>
              `${
                isActive && isScrolled
                  ? "active-menu-scrolled"
                  : isActive && "active-menu"
              }`
            }
          >
            Preguntas frecuentes
          </NavLink>
        </li>
        <li>
          <button className="btn-primary">Afiliado</button>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
