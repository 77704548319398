import React, { useState } from "react";
import blogs from "assets/Blogs.json";
import AsideBlogs from "components/AsideBlogs";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { NavLink } from "react-router-dom";
import "swiper/css";
import "styles/blog.css";
import Search from "components/Search/Search";
import BlogIndex from "components/blog/BlogIndex";
const Blogs = () => {
  const [search, setSearch] = useState("");
  const [activeCategory, setActiveCategory] = useState(null);

  const filterBlogs = () => {
    const searchWords = search
      .split(",")
      .map((word) => word.trim().toLowerCase());
    if (searchWords.length === 0) {
      return blogs.slice();
    }
    const filtered = blogs.filter((blog) => {
      const blogKeywords = blog.keywords
        .split(",")
        .map((keyword) => keyword.trim().toLowerCase());
      const blogText = blog.text.toLowerCase();
      return searchWords.some(
        (word) =>
          blog.title.toLowerCase().includes(word) ||
          blogKeywords.includes(word) ||
          blogText.includes(word)
      );
    });

    return filtered.slice();
  };

  const handleCategory = (category, subcategory) => {
    setSearch(subcategory);
    setActiveCategory(category);
  };

  // Calcula el número total de slides
  // const totalSlides = Math.ceil(hoteles.HotelesNacionales.length / 6);

  // // Divide los hoteles en grupos de 6
  // const slides = Array.from({ length: totalSlides }, (_, slideIndex) => {
  //   const start = slideIndex * 6;
  //   const end = start + 6;
  //   return hoteles.HotelesNacionales.slice(start, end);
  // });
  return (
    <>
      {/* <div className="contenedor-relative">
        <img loading="lazy" src="/images/Blog.jpg" alt="" width={"100%"} />
        <h1 className="title-section">BLOG</h1>
      </div>
      <div className="container mt-4">
        <div className="col-lg-4 col-md-12 col-sm-12">
          <div className="form">
            <label>
              <input
                className="input"
                type="text"
                required=""
                placeholder="Buscar..."
                id="search"
                value={search}
                onChange={onSearchChange}
              />
              <div className="fancy-bg"></div>
              <div className="search">
                <svg viewBox="0 0 24 24" aria-hidden="true">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
              </div>
              <button
                className="close-btn"
                type="reset"
                onClick={() => setSearch("")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"></path>
                </svg>
              </button>
            </label>
          </div>
        </div>
      </div>
      <Swiper
        navigation={true}
        loop={true}
        modules={[Navigation]}
        breakpoints={{
          400: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 4,
          },
          1624: {
            slidesPerView: 5,
          },
        }}
        className="mt-3 mySwiper"
      >
        {CategoriesBlogs.map(({ category, subcategory }, index) => (
          <SwiperSlide
            key={index}
            className={
              activeCategory === category
                ? "item-category active"
                : "item-category"
            }
            onClick={() => handleCategory(category, subcategory)}
          >
            <p>{category}</p>
          </SwiperSlide>
        ))}
      </Swiper>
      {filterBlogs().length > 0 ? (
        <>
          <div className="grid-blog">
            <div className="seccion1">
              <Swiper
                modules={[Navigation, Autoplay]}
                spaceBetween={0}
                slidesPerView={1}
                loop={true}
                navigation
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
              >
                {filterBlogs()?.map(({ title, img, id }, index) => (
                  <SwiperSlide key={index} className="cardBlog ">
                    <NavLink
                      to={`/blog/${id}`}
                      className="cardContent"
                      key={index}
                    >
                      <div className="degradado">
                        <img loading="lazy" src={img[1]?.img} alt={title} />
                      </div>
                      <p>{title}</p>
                    </NavLink>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {filterBlogs()
              ?.slice(0, 4)
              ?.map(({ title, img, id }, index) => (
                <NavLink
                  to={`/blog/${id}`}
                  className={`seccion${index + 2} cardBlog`}
                  key={index}
                >
                  <div className="cardContent">
                    <div className="degradado">
                      <img loading="lazy" src={img[0]?.img} alt={title} />
                    </div>
                    <p>{title}</p>
                  </div>
                </NavLink>
              ))}
          </div>
          <div className="second-grid-blog">
            {filterBlogs().length > 4 ? (
              <NavLink to={`/blog/${filterBlogs()?.[4].id}`} className="note1">
                <img
                  loading="lazy"
                  src={filterBlogs()[4]?.img[0].img}
                  className="img-fluid"
                  alt={filterBlogs()[4]?.title}
                />
                <p className="title-note">
                  <b>{filterBlogs()[4]?.title}</b>
                </p>
                <p
                  className="limit-text"
                  dangerouslySetInnerHTML={{ __html: filterBlogs()[4]?.text }}
                ></p>
              </NavLink>
            ) : (
              ""
            )}
            {filterBlogs()
              ?.slice(5, 8)
              ?.map(({ img, title, id }, index) => (
                <NavLink
                  to={`/blog/${id}`}
                  className={`note${index + 2}`}
                  key={title + index}
                >
                  <div className="tarjeta">
                    <img
                      loading="lazy"
                      src={img[0]?.img}
                      alt={title}
                      className="imagen"
                    />
                    <div className="contenido">
                      <p className="card-title">
                        <b>{title}</b>
                      </p>
                    </div>
                  </div>
                </NavLink>
              ))}
            {filterBlogs().length > 9 ? (
              <div className="note5">
                <div className="column">
                  <AsideBlogs blogs={filterBlogs().slice(9, 15)} />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {filterBlogs().length > 8 ? (
            <section style={{ padding: "1em 2em" }}>
              <h2 className="h2">Vuelve a leer</h2>
              <div className="line-title" style={{ width: "10%" }}></div>
            </section>
          ) : (
            ""
          )}
          <div className="third-grid-blog">
            {filterBlogs()
              ?.slice(8)
              ?.map(({ title, img, id }) => (
                <NavLink to={`/blog/${id}`} className="custom-card" key={id}>
                  <img
                    loading="lazy"
                    src={img[0]?.img}
                    alt={title}
                    className="custom-image"
                  />
                  <h2 className="custom-title">{title}</h2>
                </NavLink>
              ))}
          </div>
        </>
      ) : (
        <h1 className="text-center m-5 p-5">Sin resultados</h1>
      )} */}

      <section className="layout-card-blog">
        {filterBlogs()
          ?.slice(0, 3)
          .map((blog, index) => (
            <NavLink
              to={`/blog/${blog.id}`}
              key={blog.id}
              className={"card-blog"}
            >
              <img src={blog.img[0].img} alt={blog.title} />
              <h4
                className={`text-title-blog ${
                  index === 1 ? "second" : index === 2 && "third"
                }`}
              >
                {blog.title}
              </h4>
            </NavLink>
          ))}
      </section>
      <section className="layout-grid-blogs">
        <div className="add-row3">
          <Search search={search} setSearch={setSearch} />
          <BlogIndex
            activeCategory={activeCategory}
            handleCategory={handleCategory}
          />
        </div>
        {filterBlogs()
          ?.slice(0, 6)
          .map((blog, index) => (
            <NavLink
              to={`/blog/${blog.id}`}
              key={blog.id}
              className={
                "item-blog " +
                (index === 0 || index === 2 || index === 4
                  ? "add-col2"
                  : index === 1 && "add-row2")
              }
            >
              <img src={blog.img[0].img} alt={blog.title} />
              <h6 className="title-blog-item">{blog.title}</h6>
            </NavLink>
          ))}
      </section>
      <section className="layout-grid-blogs second">
        {filterBlogs()
          ?.slice(6)
          .map((blog, index) => (
            <NavLink
              to={`/blog/${blog.id}`}
              key={blog.id}
              className={
                "item-blog " +
                (index === 1 || index === 4 || index === 6 ? "add-col2" : "")
              }
            >
              <img src={blog.img[0].img} alt={blog.title} />
              <h6 className="title-blog-item">{blog.title}</h6>
            </NavLink>
          ))}
      </section>
      {/* <section className="layout-grid-blogs">
        <div className="add-row4">1</div>
        <div className="add-col2">2</div>
        <div className="add-row2">3</div>
        <div className="add-col2">4</div>
        <div className="">5</div>
        <div className="add-col2">6</div>
      </section>
      <section className="layout-grid-blogs second">
        <div className="">7</div>
        <div className="add-col2">8</div>
        <div className="">9</div>
        <div className="">10</div>
        <div className="add-col2">11</div>
        <div className="">12</div>
        <div className="add-col2">13</div>
      </section> */}
    </>
  );
};

export default Blogs;
