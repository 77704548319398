import React from "react";
import { NavLink } from "react-router-dom";

const AsideBlogs = ({ blogs }) => {
  return (
    <aside>
      <h2 className="text-center h2">No te pierdas</h2>
      <div className="line-title"></div>
      <ul>
        {blogs?.map((blog) => (
          <li key={blog.id} className="li-custom">
            <NavLink to={`/blog/${blog.id}`}>{blog.title}</NavLink>
          </li>
        ))}
      </ul>
    </aside>
  );
};

export default AsideBlogs;
