import React from "react";
import OutlinedText from "common/OutlinedText";
import Slider from "components/video-slider/Slider";
import hoteles from "assets/HotelesNacionales.json";
import { NavLink } from "react-router-dom";
import "styles/hotels/grid-hotels.css";

const GridHotels = () => {
  // Calcula el número total de slides
  const totalSlides = Math.ceil(hoteles.HotelesNacionales.length / 6);

  // Divide los hoteles en grupos de 6
  const slides = Array.from({ length: totalSlides }, (_, slideIndex) => {
    const start = slideIndex * 6;
    const end = start + 6;
    return hoteles.HotelesNacionales.slice(start, end);
  });

  return (
    <div className="container-grid-hotels">
      <OutlinedText
        position={true}
        positionTop1="16%"
        left_text1="8%"
        text1="Hoteles"
        text2="Nuestros"
        color="var(--fondo-defecto)"
        positionTop2="0"
        left_text2="5%"
      />

      <Slider
        showAlternButtons={true}
        showIndicators={false}
        showButtons={false}
        totalSlidesSend={totalSlides} // Envía el total de slides al Slider
      >
        {slides.map((hotelesGrupo, slideIndex) => (
          <section className="layout-grid-hotel" key={slideIndex}>
            {hotelesGrupo.map((hotel, index) => (
              <NavLink
                to={`/${hotel.Destino}/${hotel.Hotel}`}
                className={`item-hotel ${
                  index === 2 ? "two-row" : index === 5 && "two-colum"
                }`}
                key={index}
              >
                <img
                  loading="lazy"
                  src={hotel.slider[1].img}
                  alt={hotel.Hotel}
                  className="img-item-hotel"
                />
                <div className="info-overlay">
                  <div>
                    <h1>{hotel.Hotel}</h1>
                    <span>{hotel.detalles[1].Ciudad}</span>
                  </div>
                  <NavLink className="show-more-link" to="">
                    ver más
                  </NavLink>
                  <div className="container-flag">
                    <img src={hotel.flag} alt="flag" className="flag" />
                  </div>
                </div>
              </NavLink>
            ))}
          </section>
        ))}
      </Slider>
    </div>
  );
};

export default GridHotels;
